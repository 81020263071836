import React from 'react'
import Layout from '../components/indexLayout'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

//TODO: Kundenbewertungen: Dynamische Umsetzung mit WordPress!?

const KundenbewertungenPage = props => (
  <Layout>
    <div className="page">
      <div className="container">
        <section className="hero-section">
          <div className="hero-texts">
            <p className="sosc-header">
            Kundenbewertungen
            </p>
            <div className="dash" />
            <p>
              Sehr geehrte Kunden,<br /><br />
              ich würde mich sehr darüber freuen, wenn Sie mir hier, auf dieser Seite, 
              Ihre Erfahrung mitteilen würden bzgl. 
              meiner Unterstützung im Erwerb der für Sie richtigen Immobilie oder im Verkauf Ihrer Immobilie. 
              Auf diese Weise helfen Sie mir mich stets zu verbessern, 
              um die Wünsche meiner Kunden zur vollsten Zufriedenheit zu erfüllen.<br /><br />
              
              Aktuell wird die Eingabe zur Kundenbewertungen überarbeitet und steht daher leider nicht zur Verfügung.
              Wir arbeiten an einer Lösung, damit Sie mir wieder Kundenbewertungen zukommen lassen können.
            </p>
          </div>
          <div>
            <Img
              fluid={props.data.imageOne.childImageSharp.fluid}
              className="hero-image"
              alt="hero image"
            />
          </div>
        </section>
      </div>

      <div className="container">
        <section className="hero-section">
          <div className="hero-texts">
            <p className="sosc-header">
            Bisherige Kundeneinträge
            </p>
            
            <p>
              <b>Roland Trier aus Lüdenscheid schrieb am 1. April 2019 um 8:56:</b><br />
              Wir danken Herrn Vogl für eine sehr kompetente Begleitung von A bis Z zu unserem Immobilienkauf.
              Er hat sehr gut zwischen uns und den Verkäufern vermittelt und wußte unsere interessen gut zu vertreten, 
              wobei er sympathisch, zuverlässig unkompliziert und absolut kompetent agiert.
              Es lief alles schnell und reibungslos ab. Wie unser Vorgänger können wir ihn Unbedingt weiterempfehlen!!!
              Vielen Dank Herr Vogl und schönen Gruß Fam. Trier
            </p>

            <p>
              <b>Olaf Harnisch aus Hagen schrieb am 17. Oktober 2018 um 21:49:</b><br />
              Herr Vogl ist ein Makler, der sehr kompetent zwischen Käufer und Verkäufer vermittelt.
              Er ist sehr zuverlässig, symphatisch und mit gutem Fachwissen ausgestattet.
              Herrn Vogl würden wir jederzeit wieder engagieren und weiterempfehlen.
            </p>

            <p>
              <b>Michael aus Lüdenscheid schrieb am 3. März 2017 um 1:55:</b><br />
              Wir haben Herrn Vogl als einen sehr engagierten Makler kennengelernt. 
              Er verstand, auch im Interesse des Verkäufers, Brücken zu bauen und Zweifel auszuräumen. 
              Dabei waren wir angenehm von der Offenheit und Transparenz während der gesamten Erwerbsphase überrascht. 
              Sofern wir zukünftig noch einmal die Dienstleistung eines Maklers benötigen, wäre Herr Vogl unsere erste Wahl. 
            </p>

            <p>
              <b>S.Plate schrieb am 15. Januar 2016 um 17:58:</b><br />
              Herr Vogl ist ein kompetenter und freundlicher Geschäftspartner, er hat sich sehr bemüht mein Objekt zu vermarkten. 
              Immer wieder gerne wenn es um Immobilien geht!
            </p>

            <p>
              <b>Soysal aus Meinerzhagen schrieb am 5. Januar 2016 um 19:22:</b><br />
              Herr Vogl ist ein sehr vertrauenswürdiger Mensch der sich in seiner Branche bestens auskennt und dabei noch sympathisch, 
              freundlich, kompetent , flexibel, strukturiert und realistisch ist. 
              Für mich der beste Makler den ich kennengelernt habe. 
            </p>

            <p>
              <b>Unruh Aron & Lena aus Herscheid schrieb am 6. Oktober 2015 um 21:25:</b><br />
              Unsere Meinung war, dass wir den Makler zwei Mal zu Gesicht bekommen: 
              1. Bei der Besichtigung des Hauses... und 2. bei dem Notar. 
              Herr Vogl hat uns aber des Besseren belehrt: Jederzeit einsatzbereit und mit seiner freundlichen Art, 
              dazu mit unendlicher Geduld ist er uns eine unersetzliche Unterstützung bei dem Verkauf unseres Hauses geworden. 
              Dafür bedanken wir uns ganz herzlich und wünschen ihm noch viele freundliche Kunden.
            </p>

            <p>
              <b>Helga Köhmstedt aus Lüdenscheid schrieb am 29. Juli 2015 um 14:30:</b><br />
              mieten, kaufen, wohnen - die dokusoap auf VOX. 
              Herr Vogl könnte dort gut mitmachen. 
              Sehr engagiert, gibt nicht auf, auch wenn es schwierig wird, findet er eine Lösung. 
              Ich war sehr zufrieden. 
              Eine sehr angenehme sympathische Zusammenarbeit. 
              Frau hat nicht das Gefühl, von einem eingebildeten Besserwisser (sind leider viele Makler) über den Tisch gezogen zu werden.
            </p>

            <p>
              <b>Klapic aus Meinerzhagen schrieb am 19. Juli 2015 um 16:38:</b><br />
              Wir waren sehr zufrieden. Ein sympathischer und zeitlich flexibler Makler. 
            </p>

            <p>
              <b>Dr. Georg Wroblewski aus Altena schrieb am 28. Juni 2015 um 14:08:</b><br />
              Vom Beginn bis zur Übergabe stand Herr Vogl mit Kompetenz und Unterstützung bereit. Ein sehr angenehmer Makler. 
            </p>
          
          </div>
        </section>
      </div>

    </div>
  </Layout>
)

export const firstSiteImages = graphql`
  fragment firstSiteImages on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "images/undraw_personal_text_vkd8_transparent.png" }) {
      ...firstSiteImages
    }
  }
`

export default KundenbewertungenPage
